import { useCallback } from "react";

export const useRequest = () => {
  const request = useCallback(async (method, url, body) => {
    const response = await fetch(url, {
      method: method,
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(body),
      credentials: "include"
    });
    return await response.json();
  }, []);

  return request;
};
