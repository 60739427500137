import React from "react";
import Swiper from "react-id-swiper";
import "swiper/css/swiper.css";
import { useWindowSize } from "../../hooks/window-size";
import {
  GamesWrapper,
  Title,
  GamesGrid,
  GameItem,
  GameImage,
  GameTitle,
  GameHover,
  GameTitleHover,
  GamePlayButton,
  GameImg,
  SwiperInnerWrapper,
  SwiperWrapper
} from "./styles";

export const Games = ({ title, games, onPlayGame, isMobile }) => {
  return (
    <GamesWrapper>
      <Title>{title}</Title>
      <GamesGrid>
        {games.map((game, i) => (
          <Game
            isMobile={isMobile}
            onPlayGame={() => onPlayGame(game)}
            key={i}
            title={game.title}
            image={game.thumbnailUrl}
          />
        ))}
      </GamesGrid>
    </GamesWrapper>
  );
};

export const GamesSwiper = ({ title, games, onPlayGame, isMobile }) => {
  const windowSize = useWindowSize();
  let slidesPerView = 1;

  if (windowSize.width > 415) {
    slidesPerView = 1;
  }
  if (windowSize.width > 366 * 2) {
    slidesPerView = 2;
  }
  if (windowSize.width > 366 * 3) {
    slidesPerView = 3;
  }
  if (windowSize.width > 366 * 4) {
    slidesPerView = 4;
  }

  const params = {
    slidesPerView,
    spaceBetween: 0,
    centeredSlides: slidesPerView === 1,
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
      dynamicBullets: true
    }
  };
  return (
    <GamesWrapper>
      <Title>{title}</Title>
      <SwiperWrapper>
        <SwiperInnerWrapper>
          <Swiper {...params}>
            {games.map((game, i) => (
              <div key={i}>
                <Game
                  isMobile={isMobile}
                  onPlayGame={() => onPlayGame(game)}
                  key={i}
                  title={game.title}
                  image={game.thumbnailUrl}
                />
              </div>
            ))}
          </Swiper>
        </SwiperInnerWrapper>
      </SwiperWrapper>
    </GamesWrapper>
  );
};

export const Game = ({ title, image, onPlayGame, isMobile }) => {
  return (
    <GameItem onClick={isMobile ? onPlayGame : undefined}>
      <GameImage>
        <GameImg src={image} alt={title} />
      </GameImage>
      <GameTitle>{title}</GameTitle>
      {!isMobile && (
        <GameHover className="hover-actions">
          <GameTitleHover>{title}</GameTitleHover>
          <GamePlayButton onClick={onPlayGame}>Play</GamePlayButton>
        </GameHover>
      )}
    </GameItem>
  );
};
