import styled from "styled-components";

export const AlertWrapper = styled.div`
  position: fixed;
  width: 100%;
  height: 100vh;
  left: 0px;
  top: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  z-index: 99999;
`;

export const AlertBackdrop = styled.div`
  position: fixed;
  width: 100%;
  height: 100vh;
  left: 0px;
  top: 0px;
  background: radial-gradient(50% 50% at 50% 50%, #230639 0%, #090217 100%);
  mix-blend-mode: normal;
  opacity: 0.7;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  z-index: 99999;
`;

export const AlertBox = styled.div`
  width: 384px;
  min-height: 120px;
  background: linear-gradient(180deg, #54179c 0%, #1f083a 100%);
  border: 1px solid rgba(137, 51, 238, 0.8);
  box-sizing: border-box;
  box-shadow: 0px 2px 25px rgba(165, 60, 248, 0.72);
  border-radius: 12px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 999999;
  @media (max-width: 420px) {
    width: 310px;
  }
`;

export const Message = styled.div`
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 140%;
  text-align: center;
  color: #ffffff;
  margin-bottom: 20px;
`;

export const Button = styled.div`
  width: 100%;
  height: 38px;
  background: linear-gradient(180deg, #8933ee 0%, #551c97 76.32%);
  box-shadow: 0px 4px 6px rgba(134, 47, 245, 0.2);
  border-radius: 4px;
  font-family: Lato;
  font-style: normal;
  font-weight: 800;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.2em;
  color: #ffffff;
  padding: 9px;
`;
