import React, {useState, useEffect, useRef} from "react";
import {useUser} from "../../hooks/user";
import {useWindowSize} from "../../hooks/window-size";
import {GOOGLE_FORM_LINK} from "../../constants";
import {
    LoginForm,
    RegisterForm,
    LogoutButton,
    UserInfo,
    RefreshIcon,
    HeaderMGroup,
    Header,
    Group,
    Logo
} from "../../components";
import {AlertBackdrop, AlertBox, AlertWrapper, Button, Message} from "../../components/Alert/styles";
import {PopupBackdrop, PopupBox, PopupWrapper} from "../../components/Header/styles";

export const HeaderContainer = () => {
    const {
        logining,
        fetching,
        login,
        register,
        logout,
        userInfo,
        updateUserInfo
    } = useUser();
    const windowSize = useWindowSize();
    const ref = useRef();
    let [signInShow, setSignInShow] = useState(false);
    const [form, setForm] = useState({
        login: "",
        password: ""
    });

    const [rendered, setRendered] = useState(false);

    const handleChangeForm = (e) => {
        setForm({...form, [e.target.name]: e.target.value});
    };

    const handleSubmit = () => {
        login(form.login, form.password);
    };

    const handleRegisterSubmit = () => {
        register(form.name, form.login, form.password);
    };

    const handleSwapForm = (show) => {
        setSignInShow(show);
    };

    const handleOutsideClick = (e) => {
        if (e.target === ref.current) {
            setSignInShow(false);
        }
    };
    useEffect(() => {
        setRendered(true);
        updateUserInfo();
    }, [updateUserInfo]);

    useEffect(() => {
        if (userInfo) {
            const id = setInterval(() => {
                console.log("updateUserInfo ...");
                updateUserInfo();
            }, 10000);
            return () => clearInterval(id);
        }
    }, [updateUserInfo, userInfo]);

    if (windowSize.width < 768) {
        return (
            <Header>{signInShow}
                <HeaderMGroup>
                    <Logo href={GOOGLE_FORM_LINK} target="_blank"/>
                    {userInfo && (
                        <RefreshIcon loading={fetching} onClick={updateUserInfo}/>
                    )}
                </HeaderMGroup>
                {userInfo ? (
                    <>
                        <UserInfo userName={userInfo.nick} balance={userInfo.realBalance} currency={userInfo.currencySign}/>
                        <LogoutButton disabled={logining} onClick={logout}>
                            Logout
                        </LogoutButton>
                    </>
                ) : (
                    signInShow ?
                        <RegisterForm
                            hidden={fetching || !rendered}
                            disabled={logining}
                            onSwapForm={handleSwapForm}
                            onSubmit={handleRegisterSubmit}
                            onChange={handleChangeForm}
                            login={form.login}
                            password={form.password}
                        /> : <LoginForm
                            hidden={fetching || !rendered}
                            disabled={logining}
                            onSwapForm={handleSwapForm}
                            onSubmit={handleSubmit}
                            onChange={handleChangeForm}
                            login={form.login}
                            password={form.password}
                        />

                )}
            </Header>
        );
    } else
        return (
            <Header>
                <Logo href={GOOGLE_FORM_LINK} target="_blank"/>
                {userInfo ? (
                        <>
                            <UserInfo userName={userInfo.nick} balance={userInfo.realBalance} currency={userInfo.currencySign}/>
                            <Group>
                                <RefreshIcon loading={fetching} onClick={updateUserInfo}/>
                                <LogoutButton disabled={logining} onClick={logout}>
                                    Logout
                                </LogoutButton>
                            </Group>
                        </>
                    ) :
                    (
                        (
                            <LoginForm
                                hidden={fetching || !rendered}
                                disabled={logining}
                                onSwapForm={handleSwapForm}
                                onSubmit={handleSubmit}
                                onChange={handleChangeForm}
                                login={form.login}
                                password={form.password}
                            />
                        )
                    )
                }
                {signInShow &&
                <PopupWrapper>
                    <PopupBackdrop ref={ref} onClick={handleOutsideClick}/>
                    <PopupBox>
                        <RegisterForm
                            hidden={fetching || !rendered}
                            disabled={logining}
                            onSwapForm={handleSwapForm}
                            onSubmit={handleRegisterSubmit}
                            onChange={handleChangeForm}
                            login={form.login}
                            password={form.password}
                        />
                    </PopupBox>
                </PopupWrapper>
                }
            </Header>
        );
};
