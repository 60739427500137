import styled from "styled-components";

export const LoginFormWrapper = styled.form`
   font-family: 'Lato';
   display: block;
  transition: 0.5s all;
  opacity: ${(p) => (p.hidden ? "0" : "1")};
  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    margin: 20px 0;
  }
`;

export const Input = styled.input`
  margin-left: 4px;
  margin-right: 4px;
  width: 165px;
  height: 38px;
  background: #ffffff;
  border-radius: 4px;
  border: none;
  padding: 9px;
  box-sizing: border-box;
  outline: none;
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 20px;
  color: #606060;
  &[disabled] {
    opacity: 0.5;
  }
  @media (max-width: 768px) {
    width: 100%;
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 0;
    padding-left: 30px;
  }
`;

export const Button = styled.button`
  margin-left: 4px;
  margin-right: 0px;
  width: 115px;
  height: 38px;
  background: linear-gradient(180deg, #8933ee 0%, #551c97 76.32%);
  box-shadow: 0px 4px 6px rgba(134, 47, 245, 0.2);
  border-radius: 4px;
  box-sizing: border-box;
  font-family: Lato;
  font-style: normal;
  font-weight: 800;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.2em;
  color: #ffffff;
  border: none;
  text-transform: uppercase;
  outline: none;
  &[disabled] {
    opacity: 0.5;
  }
  @media (max-width: 768px) {
    width: 100%;
    margin: 0 0 17px;
    margin-left: 0;
    margin-right: 0;
  }
`;

export const LogoutButton = styled.button`
  background-color: transparent;
  border: none;
  outline: none;
  width: 156px;
  height: 38px;
  box-shadow: 0px 4px 12px rgba(134, 47, 245, 0.5);
  border-radius: 4px;
  font-family: Lato;
  font-style: normal;
  font-weight: 800;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.2em;
  color: #ffffff;
  text-transform: uppercase;
  margin-left: 12px;
  &[disabled] {
    opacity: 0.5;
  }
  @media (max-width: 768px) {
    width: 100%;
    margin-left: 0;
    margin-bottom: 40px;
  }
`;

export const FormTitle = styled.span`
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 40px;
    display: flex;
    align-items: center;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: #FFFFFF;
`;
export const FormSubTitle = styled.span`
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 30px;
    display: flex;
    align-items: center;
    color: #FFFFFF;
`;

export const RememberMeCbx = styled.label`
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    height: 12px;
    display: flex;
    align-items: center;
    color: #FFFFFF;
    input{
       margin: 0 5px 0 0;
    }
`;
export const SignUpLbl = styled.label`
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    height: 16px;
    display: flex;
    align-items: center;
    color: #FFFFFF;
`;
export const SignUpLink = styled.span`
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    align-items: center;
    color: #913EF1;
    margin: 0 5px;
`;
