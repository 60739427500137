let BASE_SERVICE_URL = "";
if (process.env.NODE_ENV === "development") {
  BASE_SERVICE_URL = "/_Core/Service/";
} else {
  BASE_SERVICE_URL = window.BASE_SERVICE_URL || "/_Core/Service/";
}
export { BASE_SERVICE_URL };

export const EXECUTE_REQUEST_URL =
  BASE_SERVICE_URL + "external/execute_request/";
export const EXECUTE_ACTION_URL = BASE_SERVICE_URL + "mva/execute_action";
export const USER_INFO_URL = BASE_SERVICE_URL + "mva/get_user_info";

export const GOOGLE_FORM_LINK = "";
