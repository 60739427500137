import React from "react";
import { UserInfoWrapper, Group, UserName, UserBalance, Icon, Currency } from "./styles";
import userIcon from "../../assets/user-icon.svg";
import moneyIcon from "../../assets/money-icon.svg";
import refreshIcon from "../../assets/refresh-icon.svg";

export const UserInfo = ({ userName, balance, currency }) => {
  return (
    <UserInfoWrapper>
      <Group>
        <Icon src={userIcon} alt="user name" />
        <UserName>{userName}</UserName>
      </Group>
      <Group>
          <Currency alt="user balance" >{currency}</Currency>
        <UserBalance>{balance}</UserBalance>
      </Group>
    </UserInfoWrapper>
  );
};

export const RefreshIcon = ({ loading, onClick }) => {
  return (
    <Icon
      className={loading ? "disabled" : ""}
      onClick={onClick}
      src={refreshIcon}
      alt="refresh"
    />
  );
};
