import React, { createContext, useReducer, useContext } from "react";

export const SET_GAMES = "SET_GAMES";
export const SET_FEATURED_GAMES = "SET_FEATURED_GAMES";
export const SET_AUTH = "SET_AUTH";
export const SET_REG = "SET_REG";
export const SET_ALERT = "SET_ALERT";
export const SET_MAIN_LOADING = "SET_MAIN_LOADING";

export const setMainLoading = (value) => ({
  type: SET_MAIN_LOADING,
  value
});

const initialState = {
  games: [],
  featuredGames: [],
  auth: null,
  register: null,
  alert: null,
  mainLoading: false
};
const store = createContext(initialState);
const { Provider } = store;

const StateProvider = ({ children }) => {
  const [state, dispatch] = useReducer((state, action) => {
    switch (action.type) {
      case SET_GAMES:
        return { ...state, games: action.games };
      case SET_FEATURED_GAMES:
        return { ...state, featuredGames: action.games };
      case SET_AUTH:
        return { ...state, auth: action.auth };
      case SET_REG:
        return { ...state, register: action.register };
      case SET_ALERT:
        return { ...state, alert: action.message };
      case SET_MAIN_LOADING:
        return { ...state, mainLoading: action.value };
      default:
        return state;
    }
  }, initialState);

  return <Provider value={{ state, dispatch }}>{children}</Provider>;
};

const useGlobalState = () => {
  const { state, dispatch } = useContext(store);
  return [state, dispatch];
};

export { store, StateProvider, useGlobalState };
