import styled from "styled-components";

export const SliderWrapper = styled.div`
  display: flex;
  height: 480px;
  position: relative;
  border-bottom: 1px solid #8933ee;
  overflow: hidden;
  margin-bottom: 15px;
  opacity: 1;
  transition: 0.5s all;
  &.slider_loading {
    opacity: 0;
  }
`;

export const SliderBackgroud = styled.div`
  top: 0;
  left: 0;
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 1;
  & .CrossfadeImage {
    height: 100%;
    width: 100%;
  }
`;

export const SliderControls = styled.div`
  top: 0;
  left: 0;
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 2;
`;

export const LeftIcon = styled.div`
  top: 50%;
  left: 30px;
  position: absolute;
  height: 86px;
  width: 55px;
  cursor: pointer;
  transform: translateY(-50%);
  padding-top: 5px;
  & > img {
    max-width: 100%;
    max-height: 100%;
  }
  @media (max-width: 768px) {
    left: 10px;
    height: 65px;
    width: 40px;
  }
`;

export const RightIcon = styled.div`
  top: 50%;
  right: 30px;
  position: absolute;
  height: 86px;
  width: 55px;
  cursor: pointer;
  transform: translateY(-50%);
  padding-top: 5px;
  & > img {
    max-width: 100%;
    max-height: 100%;
  }
  @media (max-width: 768px) {
    right: 10px;
    height: 65px;
    width: 40px;
  }
`;

export const CenterText = styled.h2`
  margin: 0;
  position: absolute;
  display: block;
  left: 50%;
  top: 50%;
  text-align: center;
  transform: translate(-50%, -50%);
  width: calc(100% - 168px);
  font-family: Lato;
  font-style: normal;
  font-weight: 800;
  font-size: 88.2138px;
  line-height: 106px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #ffffff;
  opacity: 0.8;
  text-shadow: 0px 3.8542px 48.1775px rgba(0, 0, 0, 0.5);
  @media (max-width: 768px) {
    font-size: 39.7151px;
    line-height: 48px;
    letter-spacing: 0.02em;
    top: 40px;
    transform: translate(-50%, 0%);
    width: calc(100% - 68px);
  }
`;

export const PlayButtonWrapper = styled.div`
  margin: 0;
  position: absolute;
  right: 0;
  bottom: 0;
  text-align: center;
  display: flex;
  align-items: flex-end;
  width: 240px;
  height: 48px;
  cursor: pointer;
  & > img {
    max-width: 100%;
    max-height: 100%;
  }
`;

export const PlayButtonInner = styled.div`
  margin: 0;
  padding: 0;
  position: relative;
  text-align: center;
  display: flex;
  align-items: flex-end;
  width: 100%;
  height: 100%;
`;

export const PlayButtonBackground = styled.img`
  position: absolute;
  right: 0;
  bottom: 0;
`;

export const PlayButtonText = styled.span`
  position: absolute;
  font-family: Lato;
  font-style: normal;
  font-weight: 800;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.2em;
  color: #ffffff;
  left: 50%;
  top: 50%;
  text-align: center;
  transform: translate(-50%, -50%);
  text-transform: uppercase;
`;

export const SliderNavWrapper = styled.div`
  position: absolute;
  width: auto;
  height: 16px;
  left: 50%;
  bottom: 25px;
  transform: translateX(-50%);
  display: flex;
  @media (max-width: 768px) {
    bottom: 78px;
  }
`;

export const Dot = styled.div`
  width: 16px;
  height: 16px;
  background: #ffffff;
  opacity: ${(props) => (props.active ? "1" : "0.2")};
  box-shadow: 0px 4px 12px rgba(134, 47, 245, 0.5);
  border-radius: 8px;
  margin: 0 5px;
`;
