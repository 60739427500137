import React, {useState} from "react";
import {
    RegisterFormWrapper,
    Input,
    Button,
    LogoutButton,
    FormTitle,
    FormSubTitle,
    SignUpLbl, SignUpLink
} from "./styles";
import {KeyIco, MailIco, ShowPasswordBtn, UserIco} from "../Header/styles"

export const RegisterForm = ({
                                 disabled,
                                 hidden,
                                 onSubmit,
                                 login,
                                 name,
                                 password,
                                 termsApprove,
                                 onChange,
                                 onSwapForm
                             }) => {
    const [passwordShown, setPasswordShown] = useState(false);
    const handleSubmit = (e) => {
        e.preventDefault();
        onSubmit();
    };
    const onRegisterClick = (e) => {
        e.preventDefault();
        onSwapForm(false);
    };

    const togglePassword = () => {
        setPasswordShown(!passwordShown);
    };
    return (
        <RegisterFormWrapper hidden={hidden} onSubmit={handleSubmit}>
            <FormTitle>Create your account</FormTitle>
            <FormSubTitle>Any game. Any time. Anywhere.</FormSubTitle>
            <Input
                disabled={disabled}
                type="text"
                onChange={onChange}
                name="name"
                placeholder="Username"
                value={name}
            />
            <UserIco/>
            <Input
                disabled={disabled}
                type="email"
                onChange={onChange}
                name="login"
                placeholder="Email"
                value={login}
            />
            <MailIco/>
            <Input
                disabled={disabled}
                type={passwordShown ? "text" : "password"}
                onChange={onChange}
                name="password"
                placeholder="Password"
                value={password}
            />
            <KeyIco/>
            <ShowPasswordBtn onClick={togglePassword}>{passwordShown ? "Hide" : "Show"}</ShowPasswordBtn>
            <Button disabled={disabled} type="submit">
                Create my account
            </Button>
            <SignUpLbl>
                Already have an account?
                <SignUpLink disabled={disabled} onClick={onRegisterClick} to="/">
                    Sign in
                </SignUpLink>
            </SignUpLbl>
        </RegisterFormWrapper>
    );
};

export {LogoutButton};
