import styled from "styled-components";

export const GamesWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const Title = styled.h2`
  font-family: Lato;
  font-style: normal;
  font-weight: 800;
  font-size: 42px;
  line-height: 50px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #ffffff;
  margin: 15px 44px;
  text-overflow: ellipsis;
  max-width: 100%;
  overflow: hidden;
`;

export const GamesGrid = styled.div`
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  padding: 0 15px;
  @media (max-width: 768px) {
    padding: 0;
    justify-content: center;
  }
`;

export const SwiperWrapper = styled.div`
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0 15px;
  @media (max-width: 768px) {
    padding: 0;
  }
  & .swiper-container {
    overflow: visible;
  }
`;

export const SwiperInnerWrapper = styled.div`
  width: 100%;
  & .swiper-container {
    height: 305px;
  }
  @media (max-width: 425px) {
    width: 356px;
  }
  @media (max-width: 768px) {
    width: 356px;
  }
  @media (min-width: 768px) {
    width: 712px;
  }
  @media (min-width: 1068px) {
    width: 1068px;
  }
  & .swiper-pagination-bullet {
    width: 16px;
    height: 16px;
    background: #ffffff;
    opacity: 0.2;
    box-shadow: 0px 4px 12px rgba(134, 47, 245, 0.5);
    border-radius: 8px;
  }
  &
    .swiper-pagination-bullet.swiper-pagination-bullet-active.swiper-pagination-bullet-active-main {
    opacity: 1;
  }
  & .swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-prev {
    transform: scale(0.83);
  }
  &
    .swiper-pagination-bullets-dynamic
    .swiper-pagination-bullet-active-prev-prev {
    transform: scale(0.66);
  }
  & .swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-next {
    transform: scale(0.83);
  }
  &
    .swiper-pagination-bullets-dynamic
    .swiper-pagination-bullet-active-next-next {
    transform: scale(0.66);
  }
`;

export const GameItem = styled.div`
  width: 326px;
  height: 245px;
  background: #ffffff;
  border: 4px solid rgba(137, 51, 238, 0.8);
  box-sizing: border-box;
  box-shadow: 0px 2px 25px rgba(165, 60, 248, 0.72);
  border-radius: 12px;
  margin: 15px;
  position: relative;
  &:hover .hover-actions {
    opacity: 1;
    z-index: 1;
  }
  @media (max-width: 425px) {
    box-shadow: 0px 2px 15px rgba(165, 60, 248, 0.72);
  }
  .swiper-slide & {
    box-shadow: 0px 2px 15px rgba(165, 60, 248, 0.72);
  }
`;

export const GameImage = styled.div`
  width: 318px;
  height: 174px;
  border-radius: 8px 8px 0px 0px;
  overflow: hidden;
`;

export const GameImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const GameTitle = styled.div`
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 26px;
  text-align: center;
  letter-spacing: 0.02em;
  color: #000000;
  position: absolute;
  bottom: 0;
  height: 63px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  text-align: center;
  padding: 0 32px;
`;

export const GameTitleHover = styled(GameTitle)`
  top: 23px;
  bottom: initial;
`;

export const GameHover = styled.div`
  transition: opacity 0.5s;
  opacity: 0;
  z-index: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #ffffff;
  box-sizing: border-box;
  border-radius: 8px;
`;

export const GameButton = styled.button`
  position: absolute;
  width: 183px;
  height: 38px;
  background: linear-gradient(180deg, #8933ee 0%, #551c97 76.32%);
  box-shadow: 0px 4px 12px rgba(134, 47, 245, 0.5);
  border-radius: 4px;
  text-align: center;
  font-family: Lato;
  font-style: normal;
  font-weight: 800;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.2em;
  color: #ffffff;
  left: 50%;
  transform: translateX(-50%);
  outline: none;
`;

export const GameWatchButton = styled(GameButton)`
  top: 113px;
`;

export const GamePlayButton = styled(GameButton)`
  top: 169px;
`;
