import React from "react";
import { HeaderWrapper, LogoWrapper, Group, HeaderMGroup } from "./styles";
import logo from "../../assets/logo.svg";

export const Header = ({ children }) => {
  return <HeaderWrapper>{children}</HeaderWrapper>;
};

export const Logo = (props) => {
  return (
    <LogoWrapper {...props}>
      <img src={logo} className="App-logo" alt="logo" />
    </LogoWrapper>
  );
};

export { Group, HeaderMGroup };
