import styled from "styled-components";

export const HeaderWrapper = styled.div`
  width: 100%;
  height: 100px;
  background: linear-gradient(180deg, #54179c 0%, #1f083a 100%);
  box-shadow: 0px 4px 12px rgba(134, 47, 245, 0.5);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 30px;
  padding-right: 30px;
  box-sizing: border-box;
  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 20px;
    height: auto;
  }
`;

export const LogoWrapper = styled.a`
  display: block;
  text-decoration: none;
  width: 187px;
  height: 58px;
  & img {
    max-width: 100%;
    max-height: 100%;
  }
`;

export const Group = styled.div`
  display: flex;
  align-items: center;
`;

export const HeaderMGroup = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
`;
export const PopupWrapper = styled.div`
  position: fixed;
  width: 100%;
  height: 100vh;
  left: 0px;
  top: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  z-index: 10;
`;

export const PopupBackdrop = styled.div`
  position: fixed;
  width: 100%;
  height: 100vh;
  left: 0px;
  top: 0px;
  background: radial-gradient(50% 50% at 50% 50%, #230639 0%, #090217 100%);
  mix-blend-mode: normal;
  opacity: 0.7;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  z-index: 10;
`;

export const PopupBox = styled.div`
  width: 440;
  min-height: 320px;
  background: linear-gradient(180deg, #54179c 0%, #1f083a 100%);
  border: 1px solid rgba(137, 51, 238, 0.8);
  box-sizing: border-box;
  box-shadow: 0px 2px 25px rgba(165, 60, 248, 0.72);
  border-radius: 12px;
  padding: 20px;
  justify-content: center;
  z-index: 10;
`;

export const MailIco = styled.div`
 background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTQgNEgyMEMyMS4xIDQgMjIgNC45IDIyIDZWMThDMjIgMTkuMSAyMS4xIDIwIDIwIDIwSDRDMi45IDIwIDIgMTkuMSAyIDE4VjZDMiA0LjkgMi45IDQgNCA0WiIgc3Ryb2tlPSIjNzM3MjcyIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8cGF0aCBkPSJNMjIgNkwxMiAxM0wyIDYiIHN0cm9rZT0iIzczNzI3MiIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9zdmc+Cg==);
 width: 16px;
 height: 16px;
 background-size: contain;
 position: relative;
 top: -26px;
 left: 7px;`;

export const KeyIco = styled.div`
background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE1LjUgNy41TDE5IDRNMjEgMkwxOSA0TDIxIDJaTTExLjM5IDExLjYxQzExLjkwNjQgMTIuMTE5NSAxMi4zMTY4IDEyLjcyNiAxMi41OTc4IDEzLjM5NDhDMTIuODc4NyAxNC4wNjM1IDEzLjAyNDYgMTQuNzgxMyAxMy4wMjcxIDE1LjUwNjZDMTMuMDI5NSAxNi4yMzIgMTIuODg4NCAxNi45NTA3IDEyLjYxMTkgMTcuNjIxM0MxMi4zMzU1IDE4LjI5MTkgMTEuOTI5MSAxOC45MDEyIDExLjQxNjIgMTkuNDE0MUMxMC45MDMzIDE5LjkyNzEgMTAuMjk0IDIwLjMzMzQgOS42MjMzMyAyMC42MDk5QzguOTUyNzEgMjAuODg2NCA4LjIzNDAzIDIxLjAyNzUgNy41MDg2NiAyMS4wMjVDNi43ODMzIDIxLjAyMjYgNi4wNjU1NyAyMC44NzY3IDUuMzk2ODIgMjAuNTk1OEM0LjcyODA3IDIwLjMxNDggNC4xMjE1IDE5LjkwNDMgMy42MTIwMyAxOS4zODhDMi42MTAxNiAxOC4zNTA3IDIuMDU1NzkgMTYuOTYxNCAyLjA2ODMyIDE1LjUxOTNDMi4wODA4NSAxNC4wNzcyIDIuNjU5MjggMTIuNjk3NyAzLjY3OTAzIDExLjY3OEM0LjY5ODc3IDEwLjY1ODMgNi4wNzgyNCAxMC4wNzk4IDcuNTIwMzIgMTAuMDY3M0M4Ljk2MjQxIDEwLjA1NDggMTAuMzUxNyAxMC42MDkxIDExLjM4OSAxMS42MTFMMTEuMzkgMTEuNjFaTTExLjM5IDExLjYxTDE1LjUgNy41TDExLjM5IDExLjYxWk0xNS41IDcuNUwxOC41IDEwLjVMMjIgN0wxOSA0TDE1LjUgNy41WiIgc3Ryb2tlPSIjNzM3MjcyIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8L3N2Zz4K);
 width: 16px;
 height: 16px;
 background-size: contain;
 position: relative;
 top: -26px;
 left: 7px;`;

export const UserIco = styled.div`
background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTIwIDIxVjE5QzIwIDE3LjkzOTEgMTkuNTc4NiAxNi45MjE3IDE4LjgyODQgMTYuMTcxNkMxOC4wNzgzIDE1LjQyMTQgMTcuMDYwOSAxNSAxNiAxNUg4QzYuOTM5MTMgMTUgNS45MjE3MiAxNS40MjE0IDUuMTcxNTcgMTYuMTcxNkM0LjQyMTQzIDE2LjkyMTcgNCAxNy45MzkxIDQgMTlWMjEiIHN0cm9rZT0iIzczNzI3MiIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPHBhdGggZD0iTTEyIDExQzE0LjIwOTEgMTEgMTYgOS4yMDkxNCAxNiA3QzE2IDQuNzkwODYgMTQuMjA5MSAzIDEyIDNDOS43OTA4NiAzIDggNC43OTA4NiA4IDdDOCA5LjIwOTE0IDkuNzkwODYgMTEgMTIgMTFaIiBzdHJva2U9IiM3MzcyNzIiIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPgo=);
 width: 16px;
 height: 16px;
 background-size: contain;
 position: relative;
 top: -26px;
 left: 7px;`;

export const ShowPasswordBtn = styled.div`
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    color: #737272;
    font-size: 16px;
    position: relative;
    right: 11px;
    margin-left: auto;
    width: fit-content;
    top: -44px;
`;

