import React from "react";
import {
  GamePageWrapper,
  GamePageGradient,
  GamePageBgImage,
  GameContainer,
  CloseIcon,
  GameFrame
} from "./styles";
import closeIcon from "../../assets/close-icon.svg";
import { FrameLoader } from "../Games/loader";

export const GameLoader = ({ iframeUrl, backgroundImage, onClose }) => {
  return (
    <GamePageWrapper>
      <GamePageGradient />
      <GamePageBgImage image={backgroundImage} />
      <GameContainer>
        <CloseIcon onClick={onClose}>
          <img src={closeIcon} alt="logo" />
        </CloseIcon>
        {!iframeUrl && <FrameLoader />}
        {iframeUrl && (
          <GameFrame allowFullScreen allow="autoplay;" src={iframeUrl} />
        )}
      </GameContainer>
    </GamePageWrapper>
  );
};

export const GameEmpty = ({ backgroundImage }) => {
  return (
    <GamePageWrapper>
      <GamePageGradient />
      <GamePageBgImage image={backgroundImage} />
    </GamePageWrapper>
  );
};
