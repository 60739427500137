import styled from "styled-components";

export const GamePageWrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #090217;
  mix-blend-mode: normal;
  position: relative;
`;

export const GamePageGradient = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: radial-gradient(50% 50% at 50% 50%, #230639 0%, #090217 100%);
  mix-blend-mode: normal;
  opacity: 0.7;
  z-index: 1;
`;

export const GamePageBgImage = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: url(${(p) => p.image});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  opacity: 0.7;
  z-index: 0;
`;

export const GameContainer = styled.div`
  width: 80%;
  height: auto;
  z-index: 99;
  padding-bottom: 46%;
  z-index: 99;
  background: #403a48;
  position: relative;
`;

export const CloseIcon = styled.div`
  width: 58px;
  height: 59px;
  position: absolute;
  right: -58px;
  top: -8px;
  & > img {
    max-width: 100%;
    max-height: 100%;
  }
`;

export const GameFrame = styled.iframe`
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  border: none;
`;
