import { useState, useCallback } from "react";
import { useRequest } from "./request";
import { EXECUTE_REQUEST_URL, USER_INFO_URL } from "../constants/index";
import { useGlobalState, SET_ALERT, SET_AUTH } from "../store";

export const useUser = () => {
  const [state, dispatch] = useGlobalState();
  const [logining, setLogining] = useState(false);
  const [fetching, setFetching] = useState(false);
  const userInfo = state.auth;
  const request = useRequest();

  const updateUserInfo = useCallback(async () => {
    setFetching(true);
    try {
      const response = await request("GET", USER_INFO_URL);
      if (response && response.result === 100) {
        dispatch({ type: SET_AUTH, auth: response });
      }
    } catch (e) {
      dispatch({ type: SET_ALERT, message: e.message });
    }
    setFetching(false);
  }, [request, dispatch]);

  const login = useCallback(
    async (username, password) => {
      setLogining(true);
      try {
        const response = await request("POST", EXECUTE_REQUEST_URL, {
          requestId: "external_login",
          username,
          password
        });
        if (response && response.IsSuccess) {
          window.location.reload();
          //await updateUserInfo();
        } else if (response && response.Message) {
          dispatch({ type: SET_ALERT, message: response.Message });
        }
      } catch (e) {
        dispatch({ type: SET_ALERT, message: e.message });
      }
      setLogining(false);
    },
    [request, dispatch]
  );

  const register = useCallback(
    async (name, email, password) => {
      setLogining(true);
      try {
        const response = await request("POST", EXECUTE_REQUEST_URL, {
          requestId: "external_register",
          name,
          email,
          password
        });
        if (response && response.IsSuccess) {
          window.location.reload();
        } else if (response && response.Message) {
          dispatch({ type: SET_ALERT, message: response.Message });
        }
      } catch (e) {
        dispatch({ type: SET_ALERT, message: e.message });
      }
      setLogining(false);
    },
    [request, dispatch]
  );

  const logout = useCallback(async () => {
    setLogining(true);
    try {
      const response = await request("POST", EXECUTE_REQUEST_URL, {
        requestId: "external_logout"
      });
      if (response && response.IsSuccess) {
        window.location.reload();
      } else if (response && response.Message) {
        dispatch({ type: SET_ALERT, message: response.Message });
      }
    } catch (e) {
      dispatch({ type: SET_ALERT, message: e.message });
    }
    setLogining(false);
  }, [request, dispatch]);

  return {
    logining,
    fetching,
    login,
    register,
    logout,
    updateUserInfo,
    userInfo
  };
};
