import React from "react";
import leftIcon from "../../assets/slider-left-icon.svg";
import rightIcon from "../../assets/slider-right-icon.svg";
import playBtn from "../../assets/slider-play-btn.svg";
import sliderBg from "../../assets/slider-bg.png";
import CrossfadeImage from "react-crossfade-image";
import {
  SliderWrapper,
  SliderBackgroud,
  SliderControls,
  LeftIcon,
  RightIcon,
  CenterText,
  PlayButtonWrapper,
  PlayButtonInner,
  PlayButtonText,
  SliderNavWrapper,
  Dot
} from "./styles";

export const Slider = ({
  loading,
  currentImage,
  count,
  onNext,
  onPrev,
  onSelect,
  activeIndex,
  title,
  onPlay
}) => {
  return (
    <SliderWrapper className={loading ? "slider_loading" : ""}>
      <SliderBackgroud>
        <CrossfadeImage
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            objectPosition: "center"
          }}
          src={currentImage || sliderBg}
        />
      </SliderBackgroud>
      <SliderControls>
        <LeftIcon onClick={onPrev}>
          <img src={leftIcon} className="leftIcon" alt="left" />
        </LeftIcon>
        <RightIcon onClick={onNext}>
          <img src={rightIcon} className="rightIcon" alt="right" />
        </RightIcon>
        <PlayButton onClick={onPlay} />
        <SliderNav onSelect={onSelect} active={activeIndex} count={count} />
      </SliderControls>
    </SliderWrapper>
  );
};

const SliderNav = ({ onSelect, active, count }) => (
  <SliderNavWrapper>
    {Array(count)
      .fill(0)
      .map((_, i) => (
        <Dot key={i} onClick={onSelect.bind(null, i)} active={active === i} />
      ))}
  </SliderNavWrapper>
);

const PlayButton = ({ onClick }) => (
  <PlayButtonWrapper onClick={onClick}>
    <PlayButtonInner>
      <img src={playBtn} className="playBtn" alt="play button" />
      <PlayButtonText>Play</PlayButtonText>
    </PlayButtonInner>
  </PlayButtonWrapper>
);
