import React, { useState, useEffect } from "react";
import { useRequest } from "../../hooks/request";
import { GameLoader, GameEmpty } from "../../components";
import { useParams, useHistory, useLocation } from "react-router-dom";
import { EXECUTE_REQUEST_URL } from "../../constants/index";
import { isMobile } from "../../hooks/games";

const _isMobile = isMobile();

const listener = (event) => {
  if (event.data.type === "error") {
    //... read and process event.data.message …
    console.log("//... read and process event.data.message …");
  } else if (event.data.type === "loadSuccess") {
    //... process game loaded event…
    console.log("//... process game loaded event…");
  } else if (event.data.type === "cashier") {
    //... request for cashier form…
    console.log("//... request for cashier form…");
  } else if (event.data.type === "home") {
    //... redirect to main page (event appears, if lobbyUrl parameter wasn’t provided for loader url)…
    window.document.location.href = "/";
  } else if (event.data.type === "refresh") {
    //... reload the game (session token should be refreshed too)…
    console.log(
      "//... reload the game (session token should be refreshed too)…"
    );
  }
};
export const GamePage = () => {
  const request = useRequest();
  const history = useHistory();
  const location = useLocation();
  const { instanceId, gameId } = useParams();
  const params = new URLSearchParams(location.search);
  const mode = params.get("mode") || "";
  const [gameInfo, setGameInfo] = useState();

  const handleClose = () => {
    history.push("/");
  };

  useEffect(() => {
    console.log({ instanceId, gameId, mode });
    request("POST", EXECUTE_REQUEST_URL, {
      requestId: "external_game",
      gameId,
      instanceId,
      mode
    }).then((data) => {
      setGameInfo(data.ResponseData);
    });
  }, [request, instanceId, gameId, mode]);

  useEffect(() => {
    if (window.addEventListener) {
      window.addEventListener("message", listener, false);
    } else {
      window.attachEvent("onmessage", listener);
    }
    return () => {
      window.removeEventListener("message", listener);
    };
  }, []);

  useEffect(() => {
    if (gameInfo && window.WEBSITE_NAME) {
      window.document.title = `${gameInfo.info.title} - ${window.WEBSITE_NAME}`;
    }
  }, [gameInfo]);

  if (_isMobile /*|| gameId === "liveLobby"*/ && gameInfo && gameInfo.data) {
    window.location.href = gameInfo.data;
  }

  if (_isMobile) {
    return <GameEmpty />;
  }

  return (
    <GameLoader
      onClose={handleClose}
      iframeUrl={gameInfo ? gameInfo.data : null}
      backgroundImage={gameInfo ? gameInfo.info.backgroundUrl : null}
    />
  );
};
