import React from "react";
import { Games, GamesSwiper } from "../../components";
import { useGames, isMobile } from "../../hooks/games";

export const GamesContainer = () => {
  const { games, openGame } = useGames();

  const handlePlayGame = (game) => {
    openGame(game);
  };

  const _isMobile = isMobile();
  return (
    <>
      {games.map((category, i) => {
        const GamesComponent =
          !_isMobile || category.games.length <= 1 ? Games : GamesSwiper;
        return (
          <GamesComponent
            onPlayGame={handlePlayGame}
            key={i}
            title={category.title}
            games={category.games}
            isMobile={_isMobile}
          />
        );
      })}
    </>
  );
};
