import styled, { keyframes } from "styled-components";

export const Container = styled.div`
  width: 1098px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  @media (max-width: 1098px) {
    overflow: hidden;
  }
`;

export const ContainerShadow = styled.div`
  background: linear-gradient(180deg, #380d69 0%, #260948 100%);
  box-shadow: 0px 4px 10px #862ff5;
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
`;

export const AppWrapper = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  box-sizing: border-box;
  background: #2d1559;
  mix-blend-mode: hue;
  min-height: 100vh;
  color: #ffffff;
  @media (max-width: 1098px) {
    mix-blend-mode: initial !important;
  }

  & * {
    box-sizing: border-box;
  }
`;

export const AppShadow = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
  background: linear-gradient(
    180deg,
    #000000 0%,
    rgba(29, 6, 58, 0.17) 54.53%,
    #1a0832 100%
  );
  @media (max-width: 768px) {
    display: none;
  }
`;

export const AppBackgroud = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: -2;
  background-image: url(${(p) => p.image});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  @media (max-width: 768px) {
    display: none;
  }
`;

export const Padding = styled.div`
  width: 100%;
  padding-bottom: ${(p) => p.bottom || 0}px;
  padding-top: ${(p) => p.top || 0}px;
  padding-left: ${(p) => p.left || 0}px;
  padding-right: ${(p) => p.right || 0}px;
`;

const spinner = keyframes`
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
`;

export const AppLoader = styled.div`
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  &:before {
    content: "";
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
  }

  &:not(:required) {
    /* hide "loading..." text */
    font: 0/0 a;
    color: transparent;
    text-shadow: none;
    background-color: transparent;
    border: 0;
  }

  &:not(:required):after {
    content: "";
    display: block;
    font-size: 10px;
    width: 1em;
    height: 1em;
    margin-top: -0.5em;
    -webkit-animation: ${spinner} 150ms infinite linear;
    -moz-animation: ${spinner} 150ms infinite linear;
    -ms-animation: ${spinner} 150ms infinite linear;
    -o-animation: ${spinner} 150ms infinite linear;
    animation: ${spinner} 150ms infinite linear;
    border-radius: 0.5em;
    -webkit-box-shadow: rgba(255, 255, 255, 0.75) 1.5em 0 0 0,
      rgba(255, 255, 255, 0.75) 1.1em 1.1em 0 0,
      rgba(255, 255, 255, 0.75) 0 1.5em 0 0,
      rgba(255, 255, 255, 0.75) -1.1em 1.1em 0 0,
      rgba(255, 255, 255, 0.75) -1.5em 0 0 0,
      rgba(255, 255, 255, 0.75) -1.1em -1.1em 0 0,
      rgba(255, 255, 255, 0.75) 0 -1.5em 0 0,
      rgba(255, 255, 255, 0.75) 1.1em -1.1em 0 0;
    box-shadow: rgba(255, 255, 255, 0.75) 1.5em 0 0 0,
      rgba(255, 255, 255, 0.75) 1.1em 1.1em 0 0,
      rgba(255, 255, 255, 0.75) 0 1.5em 0 0,
      rgba(255, 255, 255, 0.75) -1.1em 1.1em 0 0,
      rgba(255, 255, 255, 0.75) -1.5em 0 0 0,
      rgba(255, 255, 255, 0.75) -1.1em -1.1em 0 0,
      rgba(255, 255, 255, 0.75) 0 -1.5em 0 0,
      rgba(255, 255, 255, 0.75) 1.1em -1.1em 0 0;
  }
`;
