import React, { useRef } from "react";
import {
  AlertWrapper,
  AlertBackdrop,
  AlertBox,
  Button,
  Message
} from "./styles";

export const Alert = ({ message, onClose }) => {
  const ref = useRef();
  const handleOutsideClick = (e) => {
    if (e.target === ref.current) {
      onClose && onClose(e);
    }
  };
  return (
    <AlertWrapper>
      <AlertBackdrop ref={ref} onClick={handleOutsideClick} />
      <AlertBox>
        <Message>{message}</Message>
        <Button onClick={onClose}>OK</Button>
      </AlertBox>
    </AlertWrapper>
  );
};
