import React, {useState} from "react";
import {
    LoginFormWrapper,
    Input,
    Button,
    LogoutButton,
    FormTitle,
    FormSubTitle,
    SignUpLbl, SignUpLink
} from "./styles";
import {useWindowSize} from "../../hooks/window-size";
import {KeyIco, MailIco, ShowPasswordBtn} from "../Header/styles";
import {RegisterFormWrapper} from "../RegisterForm/styles";

export const LoginForm = ({
                              disabled,
                              hidden,
                              onSubmit,
                              login,
                              password,
                              onChange,
                              onSwapForm
                          }) => {

    const windowSize = useWindowSize()
    const [passwordShown, setPasswordShown] = useState(false);
    const handleSubmit = (e) => {
        e.preventDefault();
        onSubmit();
    };
    const onSignUpClick = (e) => {
        e.preventDefault();
        onSwapForm(true);
    };

    const togglePassword = () => {
        setPasswordShown(!passwordShown);
    };

    if (windowSize.width < 768) {
        return (<LoginFormWrapper hidden={hidden} onSubmit={handleSubmit}>
                <FormTitle>Sign in</FormTitle>
                <FormSubTitle>Login to manage your account</FormSubTitle>
                <Input
                    disabled={disabled}
                    type="text"
                    onChange={onChange}
                    name="login"
                    placeholder="Email"
                    value={login}
                />
                <MailIco/>
                <Input
                    disabled={disabled}
                    type={passwordShown ? "text" : "password"}
                    onChange={onChange}
                    name="password"
                    placeholder="Password"
                    value={password}
                />
                <KeyIco/>
                <ShowPasswordBtn onClick={togglePassword}>{passwordShown ? "Hide" : "Show"}</ShowPasswordBtn>
                <Button disabled={disabled} type="submit">
                    Sign In
                </Button>
                <SignUpLbl>
                    Don’t have an account?
                    <SignUpLink disabled={disabled} onClick={onSignUpClick} to="/">
                        Sign up
                    </SignUpLink>
                </SignUpLbl>
            </LoginFormWrapper>
        );
    } else {
        return (
            <LoginFormWrapper hidden={hidden} onSubmit={handleSubmit}>
                <Input
                    disabled={disabled}
                    type="text"
                    onChange={onChange}
                    name="login"
                    placeholder="Email"
                    value={login}
                />
                <Input
                    disabled={disabled}
                    type="password"
                    onChange={onChange}
                    name="password"
                    placeholder="Password"
                    value={password}
                />
                <Button disabled={disabled} type="submit">
                    Login
                </Button>
                <Button onClick={onSignUpClick}>
                    Register
                </Button>
            </LoginFormWrapper>
        )
    }
};

export {LogoutButton};
