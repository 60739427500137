import React, { useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { StateProvider, useGlobalState, SET_ALERT } from "../../store";
import {
  AppWrapper,
  AppShadow,
  Padding,
  AppBackgroud,
  Container,
  AppLoader,
  ContainerShadow
} from "../../components";
import mainBgImage from "../../assets/main-bg.png";
import { HeaderContainer } from "../header/index";
import { SliderContainer } from "../slider/index";
import { GamesContainer } from "../games/index";
import { GamePage } from "../game/index";
import { Alert } from "../../components";

function MainPage() {
  const [state, dispatch] = useGlobalState();
  const handleCloseAlert = () => {
    dispatch({ type: SET_ALERT, message: null });
  };
  useEffect(() => {
    if (window.WEBSITE_NAME) {
      window.document.title = window.WEBSITE_NAME;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.href]);

  return (
    <AppWrapper>
      {state.mainLoading && <AppLoader />}
      <AppShadow />
      <AppBackgroud image={mainBgImage} />
      {state.alert && (
        <Alert message={state.alert} onClose={handleCloseAlert} />
      )}
      {state.register && (
          <Alert message={state.alert} onClose={handleCloseAlert} />
      )}
      <Container>
        <ContainerShadow>
          <HeaderContainer />
          <SliderContainer />
          <GamesContainer />
          <Padding bottom={30} />
        </ContainerShadow>
      </Container>
    </AppWrapper>
  );
}

function App() {
  return (
    <StateProvider>
      <Router>
        <Switch>
          <Route exact path="/game/:gameId/:instanceId" component={GamePage} />
          <Route path="/" component={MainPage} />
        </Switch>
      </Router>
    </StateProvider>
  );
}

export default App;
