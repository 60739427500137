import React, { useState, useEffect, useCallback } from "react";
import { Slider } from "../../components";
import { useFeaturedGames } from "../../hooks/games";

export const SliderContainer = () => {
  const { games, loading, openGame } = useFeaturedGames();
  const [currentIndex, setCurrentIndex] = useState(-1);

  useEffect(() => {
    if (!loading || games.length > 0) {
      setCurrentIndex(0);
    }
  }, [loading, games]);

  const handlePlay = useCallback(() => {
    if (games[currentIndex]) openGame(games[currentIndex]);
  }, [currentIndex, games, openGame]);

  const onNext = useCallback(() => {
    const i = currentIndex + 1 > games.length - 1 ? 0 : currentIndex + 1;
    setCurrentIndex(i);
  }, [currentIndex, games]);

  const onPrev = () => {
    const i = currentIndex - 1 < 0 ? games.length - 1 : currentIndex - 1;
    setCurrentIndex(i);
  };

  useEffect(() => {
    const timeOutId = setTimeout(onNext, 10000);
    return () => clearTimeout(timeOutId);
  }, [onNext]);

  const game = games.length && currentIndex > -1 ? games[currentIndex] : null;

  const props = {
    loading,
    onSelect: (i) => setCurrentIndex(i),
    onPlay: handlePlay,
    activeIndex: currentIndex,
    currentImage: game ? game.featuredUrl : null,
    count: games.length,
    title: game ? game.title : "",
    onNext,
    onPrev
  };

  return <Slider {...props} />;
};
