import styled, { keyframes, css } from "styled-components";

export const UserInfoWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 324px;
  height: 32px;
  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    height: auto;
    margin-top: 60px;
  }
`;
export const Currency = styled.div`
    width: 26px;
    height: 26px;
    text-align: center;
    line-height: 26px;
    margin: 0 8px 0 4px;
    background-color: #290650;
    border-radius: 50%;
    box-shadow: 1px 1px 4px rgba(155, 62, 228, 0.34);
`;

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const Icon = styled.img`
  width: 32px;
  height: 32px;
  margin-right: 8px;
  transform-origin: center center;
  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
  animation: ${(p) =>
    p.spin
      ? css`
          ${rotate} 1s linear infinite
        `
      : "none"};
`;

export const Group = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 768px) {
    margin-bottom: 20px;
  }
`;

export const UserName = styled.span`
  font-family: Lato;
  font-style: normal;
  font-weight: 800;
  font-size: 17px;
  line-height: 20px;
  text-align: right;
  letter-spacing: 0.02em;
  color: #9c6dff;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 160px;
`;

export const UserBalance = styled.span`
  font-family: Lato;
  font-style: normal;
  font-weight: 800;
  font-size: 17px;
  line-height: 20px;
  text-align: right;
  letter-spacing: 0.02em;
  color: #ffffff;
  text-shadow: 0px 0px 4px #b458fc;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 160px;
`;
